import { useEffect, useState } from "react";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useConfig } from "../../../../../contexts/ConfigContext";
import { useHomeContext } from "../../../../../pages/Home/HomeContent";
import {
  WelcomeContentEnum,
  WelcompeStepEnum,
} from "../../../../../types/welcome";
import ResonanceLoader from "../../../../atoms/ResonanceLoader/ResonanceLoader";
import DisplayContent from "../../../../molecules/DisplayContent";
import NextPrevButtons from "../../../../molecules/NextPrevButtons";
import useOnBoardingApi from "./useOnBoardingApi";
import Container from "../../../../atoms/Container";
import VeriticalCenter from "../../../../atoms/VerticalCenter";
import ScreenTitle from "../../../../atoms/ScreenTitle";
import styled from "styled-components";
import { useResonanceLayout } from "../../../ResonnanceLayout/ResoncanceContext";



const OnBoarding = (): ReactElement => {
  const { t } = useTranslation();
  const {
    i18n: { language },
  } = useTranslation();

  const { welcomeData, setCurrentStep, goPrevious } = useHomeContext();
  const { selectedRole, study } = useConfig();

  const [test,setTest] = useState(false);


  const contentId =
    welcomeData.contents[WelcomeContentEnum.ONBOARDING_STUDY].id;
  const studyId = welcomeData.id;
  const goToNextStep = () => {
    const has_inclusion =
      selectedRole?.inclusion_criteria?.has_inclusion_questions;
    setCurrentStep(
      has_inclusion ? WelcompeStepEnum.INCLUSION : WelcompeStepEnum.INSCRIPTION
    );
  };

  const { content, loadContent } = useOnBoardingApi(
    studyId,
    contentId,
    language
  );

  const { setResonanceLayout } = useResonanceLayout();

  useEffect(() => {   
    setResonanceLayout({
      disableHeader: false,
      disableNav: true,
      backgroundColor: "white",
      title: "onboarding_title",
    });
  }, []);

  useEffect(() => {
    loadContent();
  }, []);

  if (!content) {
    return <ResonanceLoader />;
  }

  if(test){
    return (
      <Container>
        <GroupText style={{marginTop: -15}}>
          <Title>{t("onboarding_subtitle")}</Title>
          <Text>{t("onboarding_header_text")}</Text>
          <Text>{t("onboarding_header_text2")}</Text>
        </GroupText>
        <img src='assets/images/onBoarding.png'></img>

        <NextPrevButtons
            onNextClick={goToNextStep}
            onPreviousClick={goPrevious}
          />
      </Container>

    )
  }


  if(study.is_gamified && !test){
    return (
      <Container>
        <GroupText>
          <Title>{t("onboarding_subtitle")}</Title>
          <Text>{t("onboarding_header_text")}</Text>
          <Text>{t("onboarding_header_text2")}</Text>
        </GroupText>

        <StepContainer>
        <GroupStep>
          <RedLine src="/assets/images/red_line/red_line1.png" style={{top: 140,left:0}}/>
          <Step style={{top: 155, left: 85}}>
            <Dot />
            {t("onboarding_step1")}
          <StepText>{t("onboarding_step1_text")}</StepText>
          </Step>
        </GroupStep>

        <GroupStep>
          <RedLine src="/assets/images/red_line/red_line2.png" style={{top: 140, left: 130}}/>
          <Step style={{top: 205,left: 245}}>
            <Dot />
            {t("onboarding_step2")}
            <StepText>{t("onboarding_step2_text")}</StepText>
          </Step>
        </GroupStep>

        <GroupStep>
          <RedLine src="/assets/images/red_line/red_line3.png" style={{top: 230, left: 90}}/>
          <Step style={{top: 315, left: 75}}>
            <Dot />
            {t("onboarding_step3")}
            <StepText>{t("onboarding_step3_text")}</StepText>
          </Step>
        </GroupStep>

        <GroupStep>
          <RedLine src="/assets/images/red_line/red_line4.png" style={{top: 390, left: 100}}/>
          <Step style={{top: 390, left: 220}}>
            <Dot />
            {t("onboarding_step4")}
            <StepText>{t("onboarding_step4_text")}</StepText>
          </Step>
        </GroupStep>

        <GroupStep>
          <RedLine src="/assets/images/red_line/red_line5.png" style={{top: 385, left: 260}}/>
        </GroupStep>
        </StepContainer>

        <NextPrevButtons
            onNextClick={goToNextStep}
            onPreviousClick={goPrevious}
          />

    </Container>
    )
  } else {
    return (
      <DisplayContent
        editorJsData={content.content}
        buttonComp={
          <NextPrevButtons
            onNextClick={goToNextStep}
            onPreviousClick={goPrevious}
          />
        }
      />
    )
  }
};

const StepContainer = styled.div`
// @media (min-width: 1024px) {
//     width: 80vw;
//   }

  // position: absolute;
  // top: 0;
  // left: 50%;
  // transform: translateX(-50%);
  // max-width: 100%;
  // max-height: 100%;
  // object-fit: contain;
`


const Text = styled.div`
  font-size: 16px;
  max-width: 70vw;
  margin-bottom: 8px;
`;

const StepText = styled.div`
  font-size: 16px;
  // max-width: 30vw;
  font-weight : normal;
  position : absolute;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

const Step = styled.div`
  font-size: 22px;
  font-weight: bold;
  // max-width: 30vw;
  padding-top: 15px;
  position : absolute;
`;

const GroupText = styled.div`
  margin-left: 50px;
`;

const Dot = styled.div`
  position: absolute;
  top: -15px;
  left: 16px;
  transform: translateX(-50%);
  width: 32px;
  height: 32px;
  background-color: #ffce48;
  border-radius: 50%;
  z-index: 1; 
`;

const RedLine = styled.img`
  width: auto;
  height: auto;
  position : absolute;
`;

const GroupStep = styled.div`
  width : 80%:
`;


export default OnBoarding;

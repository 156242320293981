export const home = {
  home_profile_selector_text: 'Select a profile',
  home_has_account_true: 'No, I already participate',
  home_has_account_false: 'Yes, I join the study!',
  home_is_new: 'Are you new?',
  home_forgot_password: 'Forgot password?',
  home_inscription_title: 'Registration',
  home_consent_title: 'Consent',
  home_content_check_text: 'By checking this box, I confirm that I agree with all the terms of the information document',
  home_content_none_text: 'No consent',
  home_not_consent_text: 'I do not agree with all the terms',
  home_content_signature_text: 'Sign below to give your consent',
  home_inclusion_placeholder: 'Select one',
  home_inclusion_answer_true: 'Yes',
  home_inclusion_answer_false: 'No',
  home_inclusion_on: 'About you',
  home_inclusion_off: 'No inclusion question',
  home_identifier_title: 'Choose your identifier',
  home_contact_title: 'Contact you',
  home_contact_description: 'For the study to work, you will receive reminders and info up to 2 times a month.',
  home_inscription_identifier: 'Email or nickname',
  home_inscription_firstname: 'First name',
  home_inscription_lastname: 'Last name',
  home_inscription_nickname: 'Nickname',
  home_inscription_email: 'Email',
  home_inscription_phone: 'Phone',
  home_inscription_external_id: 'File number',
  home_inscription_off: 'No identification',
  home_secret_questions: 'Secret questions',
  home_secret_question_date_placeholder: 'Select a date',
  home_secret_question_number_placeholder: 'Enter number',
  home_secret_question_text_placeholder: 'Enter text',
  home_password_title: 'Create your password',
  home_password_placeholder: 'Password',
  home_password_placeholder_confirm: 'Confirm password',
  home_login_email_placeholder: 'Email or nickname',
  home_login_nickname_placeholder: 'Nickname, email or phone',
  home_login_file_placeholder: 'Enter file number',
  home_password_description: 'The password must be at least 8 characters long and contain uppercase, lowercase, symbols, and numbers.',
  home_login_title: 'Login',
  home_login_subtitle: 'Nice to see you again!',
  home_login_button: 'Login',
  home_login_password_placeholder: 'Your password',
  home_stratification_title: '',
  home_stratification_placeholder: 'Select one',
  home_sites_text: 'Site affiliation',
  home_subsites_text: 'Sub-site affiliation',
  home_do_you_have_account: 'Do you have an account?',
  home_researchers_website: 'Researchers Website',
  home_registred_successfully: 'Congratulations, you are successfully registered.',
  home_inscription_error_EMAIL_EXIST: 'An account with this email already exists',
  home_inscription_error_NICKNAME_EXIST: 'An account with this nickname already exists',

  // Synakene Addition
  home_welcome: "Welcome to Mentalo!",
  home_description: "Take part in this major study and advance research on the mental well-being of 11-24 year olds.",
  home_anonymous_data: "Your data is confidential; researchers will not have access to your identity.",
  home_new: "Are you new?",
  home_copy_link: "Copy the study link",
  home_copied: "The link has been successfully copied!",

  home_add_mentalo: "I add Mentalo",
  home_story_telling_title: "Storytelling",
  home_story_telling_text: "Storytelling",

  home_not_included: "Thank you for your interest, but you must be between 11 and 24 years old and live in France.",
  home_not_included_desc: "To help us, you can share the study with those around you."
};


import { useIonRouter } from "@ionic/react";
import { Component, FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import Info from "../../components/atoms/ErrorComponent";
import ResonanceLoader from "../../components/atoms/ResonanceLoader/ResonanceLoader";
import SequenceContent from "../../components/organisms/SequenceDetails/SequenceContent";
import SequenceQuestionnaire from "../../components/organisms/SequenceDetails/SequenceQuestionnaire";
import { useAuth } from "../../contexts/AuthContext";
import { useConfig } from "../../contexts/ConfigContext";
import { useFetch } from "../../hooks/useFetch";
import { getComponent, getComponents } from "../../services/questionnaire.service";
import { setSequenceDone } from "../../services/sequence.service";
import { IdentificationMethodEnum } from "../../types/profile";
import {ComponentType, getComponentBodyType } from "../../types/Questionnaire";
import FinishedQuestionnaires from "../FinishedQuestionnaires/FinishedQuestionnaires";
import { PostUserGamification, UserGamificationDetails } from "../../types/welcome";
import { postUserGamification } from "../../services/user.service";
import { isMandatoryQuestionnaire } from "../../helper/questionnaire";
import { findElementsByKey } from "../../components/organisms/Home/Mountain/landscapeLogic";
import { sequenceIsDone } from "../../locales/fr/sequences.fr";
import { useResonanceLayout } from "../../components/organisms/ResonnanceLayout/ResoncanceContext";
import { throttle } from "lodash";

const SequenceComponent: FC<RouteComponentProps> = ({ history }) => {
  const { componentId, sequenceId } = useParams<{
    componentId: string;
    sequenceId: string;
    study_slug: string;
  }>();
  const { setResonanceLayout, userGamificationDetails, questionnaires } = useResonanceLayout();  
  const { user } = useAuth();
  const { study, selectedRole } = useConfig();
  const { t } = useTranslation();
  const {
    i18n: { language },
  } = useTranslation();
  const { study_slug } = useParams<{ study_slug: string }>();
  const router = useIonRouter();
  const [showSequenceDone, setShowSequenceDone] = useState(false);
  const [components, setComponents] = useState<ComponentType[]>([]);
  const { endSession } = useAuth();

  const [isMounted, setIsMounted] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { isLoading, isError, data } = useFetch<getComponentBodyType>(
    "component",
    getComponent,
    {
      studyId: study.id,
      userId: user.id,
      sequenceId,
      componentId,
      lang: language,
    }
  );

  const { sequence_id, component, nextComponent, isLastSequence } = data || {};
  const fetchComponents = async () => {
    try {
      const componentsResponse = await getComponents({
        studyId: study.id,
        userId: user.id,
        sequenceId,
        lang: language,
      });
      setComponents(componentsResponse.data);
      if (hasFinishedSequence(componentsResponse.data)) {
        setSequenceDone({ studyId: study.id, userId: user.id, sequenceId });
        setShowSequenceDone(true);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des composants:', error);
    }
  };
  useEffect(() => {
    
    if (!study.is_gamified)
      fetchComponents();
    // eslint-disable-next-line 
  }, [language, sequenceId, study.id, user.id]);

  // useEffect(() => {
  //   return () => {
  //     setIsMounted(false);
  //   };
  // }, []);

  const updateUserGamification = async (_points: number, _step: number, _animation_success: number): Promise<void> => {
    try {
      const userGamification: PostUserGamification = {
        points: _points,
        step: _step,
        animation_success: _animation_success
      };

      const userGamificationUpdates: UserGamificationDetails = {
        points: userGamificationDetails.points + _points,
        step: userGamificationDetails.step + _step,
        animation_success: userGamificationDetails.animation_success + _animation_success,
        pointsEarned: _points,
        stepEarned: _step,
        animation_successEarned: _animation_success
      };

      // if (!isMounted) return;

      setResonanceLayout({ userGamificationDetails: userGamificationUpdates });
      await postUserGamification(study.id, user.id, userGamification);

    } catch (e) {
      console.error("Error updateUserGamification : " + e);
    }
  };

  const updateQuestionnaires = async (): Promise<void> => {
    try {
      const componentIndex = questionnaires.findIndex(q => q.id == component.id);

      if (componentIndex !== -1) {
          const updatedQuestionnaires = [...questionnaires.slice(0, componentIndex), ...questionnaires.slice(componentIndex + 1)]  as ComponentType[];
          setResonanceLayout({ questionnaires: updatedQuestionnaires });
      }
    } catch (e) {
      console.error("Error updateQuestionnaires : " + e);
    }
  };

  const GetMandatoryQuestionnairePoint = (sequenceId: string): number => {
    const id = parseInt(sequenceId);
    if (id >= 71 && id <= 80) return 75;
    if ((id >= 81 && id <= 85) || (id >= 87 && id <= 91)) return 25;
    if ((id >= 92 && id <= 101) || (id >= 102 && id <= 111)) return 30;
    return 100;
  };

  const handleNext = throttle(async () => {
    if (study.is_gamified) 
    {
      if (isSubmitting) return;
      
      setIsSubmitting(true);

      try 
      {
        const _isMandatoryQuestionnaire = isMandatoryQuestionnaire(component.keywords);
      
        let points = _isMandatoryQuestionnaire 
        ? GetMandatoryQuestionnairePoint(sequence_id.toString())
        : component.points;
  
        await updateUserGamification(
          isNaN(points) ? Number(0) : points,
          _isMandatoryQuestionnaire ? Number(1) : Number(0),
          _isMandatoryQuestionnaire ? Number(0) : findElementsByKey(component.keywords)
        );
  
        await updateQuestionnaires();
  
        const components = await getComponents({
          studyId: study.id,
          userId: user.id,
          sequenceId,
          lang: language,
        });
  
        if (_isMandatoryQuestionnaire)
        {
          if (!nextComponent)
            setSequenceDone({ studyId: study.id, userId: user.id, sequenceId });
        }
        else
        {
          if (sequenceIsDone(components.data))
            setSequenceDone({ studyId: study.id, userId: user.id, sequenceId });
        }
  
        history.push(`/${study_slug}/resonance`);
      }
      catch (error) 
      {
        console.error('Erreur lors de l\'envoi des points:', error);
      } 
      finally 
      {
        setIsSubmitting(false);
      }
    } 
    else 
    {
      await fetchComponents();
      if (hasFinishedSequence(components)) 
      {
        setSequenceDone({ studyId: study.id, userId: user.id, sequenceId });
        setShowSequenceDone(true);
      }
      else 
      {
        if (nextComponent) 
        {
          history.push(`/${study_slug}/resonance/sequences/${sequenceId}/components/${nextComponent.id}`);
        } 
        else 
        {
          history.push(`/${study_slug}/resonance/sequences/`);
        }
      }
    }

  }, 2000);

  const hasFinishedSequence = (components: ComponentType[]) => {
    let finished = true;
    components?.forEach(component => {
      if (component.answered_questions_count !== component.questions_count) {
        finished = false;
      }
    });
    return finished;
  };

  const handleSequnceDone = () => {
    if (isLastSequence) {
      if (
        selectedRole.signin_criteria.method ===
        IdentificationMethodEnum.WITHOUT_IDENTIFICATION
      ) {
        endSession();
        history.replace(`/${study_slug}`);
      } else {
        history.replace(`/${study_slug}/resonance`);
      }
    } else {
      history.push(`/${study_slug}/resonance`);
    }
  };

  if (isLoading) return <ResonanceLoader />;

  if (showSequenceDone)
    return <FinishedQuestionnaires onClick={handleSequnceDone} />;

  if (isError)
    return (
      <Info
        title={t("common_server_error_title")}
        description={t("common_server_error_desc")}
        actionText={t("common_go_back")}
        onClick={() => router.canGoBack() && router.goBack()}
      />
    );

  if (!study.is_gamified)
    return (
      <SequenceContent
        studyId={study.id}
        contentId={component.content_id}
        sequenceId={parseInt(sequenceId)}
        onStartClick={handleNext}
        onPrevClick={() =>
          router.canGoBack() ? router.goBack() : history.goBack()
        }
      />
    );
  else
    return (
      <SequenceQuestionnaire
        questionnaireId={component.questionnaire_id}
        questionnaireTitle={component.questionnaire_title}
        onNextClick={handleNext}
        onPrevClick={() =>
          router.canGoBack() ? router.goBack() : history.goBack()
        }
      />
    );

  // return (
  //   <Info
  //     title={t("common_nodata_title")}
  //     description={t("common_nodata_desc")}
  //     actionText={t("common_go_back")}
  //     onClick={() => router.canGoBack() && router.goBack()}
  //   />
  // );
};

export default SequenceComponent;
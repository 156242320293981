import { useFormik } from "formik";
import { ReactElement, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../contexts/AuthContext";
import { postInclusionAnswers } from "../../../../services/user.service";
import {
  InclusionAnswerType,
  PostInclusionQuestionType,
  WelcompeStepEnum,
} from "../../../../types/welcome";
import Container from "../../../atoms/Container";
import { FormGroup } from "../../../atoms/LoginTextField";
import DisplayInclusionQuestions from "../../../molecules/Home/Inclusion/DisplayInclusionQuestions";
import NextPrevButtons from "../../../molecules/NextPrevButtons";
import * as Yup from "yup";
import styled from "styled-components";
import { useResonanceLayout } from "../../ResonnanceLayout/ResoncanceContext";
import useToast from "../../../../hooks/useToast";
import { useConfig } from "../../../../contexts/ConfigContext";
import { useHomeContext } from "../../../../pages/Home/HomeContent";
import DisplayAnonymousData from "../../../molecules/Home/Consent/DisplayAnonymousData";
import Gap from "../../../atoms/Gap";
interface DisplayInclusionProps {
  setInclusionContentId(arg: number): void;
}

// const NOT_ANSWERED_CHAR = '';

const DisplayInclusion = ({
  setInclusionContentId,
}: DisplayInclusionProps): ReactElement => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { setResonanceLayout } = useResonanceLayout();
  const present = useToast();

  const { selectedRole } = useConfig();
  const { welcomeData, goPrevious, setCurrentStep } = useHomeContext();

  const inclusion = selectedRole?.inclusion_criteria;
  const studyId = welcomeData.id;

  const onNextClick = ({
    is_eligible,
    not_eligible_content_id,
  }: PostInclusionQuestionType): void => {
    if (is_eligible) {
      setCurrentStep(WelcompeStepEnum.INSCRIPTION);
    } else {
      setCurrentStep(WelcompeStepEnum.NOT_INCLUDED);
      setInclusionContentId(not_eligible_content_id);
    }
  };

  const {
    has_inclusion_questions: hasInclusion,
    inclusion_questions: questions,
  } = inclusion;

  const postAnswers = async (values): Promise<void> => {
    try {
      if (questions.length > 0) {
        const inclusionAnswers: InclusionAnswerType[] = [];

        Object.keys(values).forEach((key) => {
          inclusionAnswers.push({
            id: parseInt(key),
            version: 1,
            answer: values[key],
          });
        });

        const { data } = await postInclusionAnswers(
          studyId,
          user.id,
          inclusionAnswers
        );
        onNextClick(data);
      } else {
        onNextClick({ is_eligible: true });
      }
    } catch (e) {
      console.error(e);
      present(e?.response?.data?.message);
    }
  };

  const inclusionSchema = useMemo(
    function () {
      const obj = {};

      questions.forEach((question) => {
        obj[question.id] = Yup.string().required().oneOf(["true", "false"]);
      });

      return Yup.object().shape(obj);
    },
    [questions]
  );

  const initialValues = useMemo(
    function () {
      const obj = {};

      questions.forEach((question) => {
        obj[question.id] = "";
      });

      return obj;
    },
    [questions]
  );

  useEffect(
    function () {
      setResonanceLayout({
        title: hasInclusion ? t("home_inclusion_on") : t("home_inclusion_off"),
      });

      return () =>
        setResonanceLayout({
          title: undefined,
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasInclusion]
  );

  const { values, touched, errors, submitForm, setFieldValue } = useFormik({
    initialValues,
    onSubmit: postAnswers,
    validationSchema: inclusionSchema,
  });

  return (
    <Container>
      {/* <EligImage src="/assets/images/eligibility.png" alt="elegiblity" /> */}
      <FormGroup>
        {hasInclusion && (
          <DisplayInclusionQuestions
            setFieldValue={(key, value) => setFieldValue(key.toString(), value)}
            values={values}
            inclusionQuestions={questions}
            errors={errors}
            touched={touched}
          />
        )}
      </FormGroup>
      <Gap size="18em" />
      <DisplayAnonymousData />

      <NextPrevButtons onNextClick={submitForm} onPreviousClick={goPrevious} />
    </Container>
  );
};

const EligImage = styled.img`
  width: 160px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

export default DisplayInclusion;

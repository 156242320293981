import { IonRouterOutlet } from "@ionic/react";
import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Route } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { useConfig } from "../contexts/ConfigContext";
import { registerNotifications } from "../helper/notifications";
import Calendar from "../pages/Calendar/Calendar";
import FinishedQuestionnaires from "../pages/FinishedQuestionnaires/FinishedQuestionnaires";
import InvitationPage from "../pages/Invitation/InvitationPage";
import LoggedHome from "../pages/LoguedHome/LoguedHome";
import Profile from "../pages/Profile/Profile";
import SequenceComponent from "../pages/SequenceComponent/SequenceComponent";
import SequenceComponentsPage from "../pages/SequenceComponents/SequenceComponents";
import SequencesPage from "../pages/Sequences/SequencesPage";
import { patchLang } from "../services/user.service";
import Gift from "../pages/Gift/Gift";

const ResonanceRoutes = (): ReactElement => {
  const { user } = useAuth();
  const { i18n } = useTranslation();
  const { study } = useConfig();

  const [data, setData] = useState<{ public_key: string }>(null);

  useEffect(() => {
    if (!study.id) {
      return;
    }
    fetch(`/assets/keys/${study.id}-web-key.json`)
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => {
        setData({ public_key: null })
        console.error('Error loading JSON:', error)
      });
  }, [study, study.id]);

  useEffect(
    function () {
      i18n.changeLanguage(user.lang);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user?.lang]
  );

  useEffect( () => {
    if (data) {
      registerNotifications({ studyId: study.id, public_key: data.public_key });
    }
  }, [study.id, data]);

  useEffect(
    function () {
      if (study?.id) patchLang(study.id, { lang: i18n.language });
    },
    [i18n.language, study]
  );

  return (
    <IonRouterOutlet>
      <Route exact path="/:study_slug/resonance">
        <LoggedHome />
      </Route>
      <Route exact path="/:study_slug/resonance/calendar">
        <Calendar />
      </Route>
      <Route exact path="/:study_slug/resonance/invite">
        <InvitationPage />
      </Route>
      <Route exact path="/:study_slug/resonance/profile">
        <Profile />
      </Route>
      {/* <Route exact path="/:study_slug/resonance/sequences">
        <SequencesPage />
      </Route> */}
      <Route exact path="/:study_slug/resonance/sequences">
        <SequenceComponentsPage />
      </Route>
      <Route exact path="/:study_slug/resonance/gift">
        <Gift />
      </Route>
      {/* <Route
        exact
        path="/:study_slug/resonance/sequences/:sequenceId/components"
      >
        <SequenceComponentsPage />
      </Route> */}
      <Route
        exact
        path="/:study_slug/resonance/sequences/:sequenceId/components/:componentId"
        component={SequenceComponent}
      />
      <Route
        exact
        path="/:study_slug/resonance/sequences/:sequenceId/finished"
        component={FinishedQuestionnaires}
      />
    </IonRouterOutlet>
  );
};

export default ResonanceRoutes;

import {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import DisplayConsent from "../../components/organisms/Home/InscriptionJourney/DisplayConsent";
import DisplayHasAccount from "../../components/organisms/Home/PublicJourney/DisplayHasAccount";
import DisplayStudyDetails from "../../components/organisms/Home/PublicJourney/DisplayStudyDetails";
import DisplayInclusion from "../../components/organisms/Home/InscriptionJourney/DisplayInclusion";
import DisplayLogin from "../../components/organisms/Home/LoginJourney/DisplayLogin";
import DisplayProfileSelector from "../../components/organisms/Home/PublicJourney/DisplayProfileSelector";
import { IdentificationMethodEnum, ProfileType } from "../../types/profile";
import {
  postConsentRequestType,
  PostUserType,
  UserStatus,
  WelcomeType,
  WelcompeStepEnum,
} from "../../types/welcome";
import OnBoarding from "../../components/organisms/Home/InscriptionJourney/OnBoarding/OnBoarding";
import DisplayInscription from "../../components/organisms/Home/InscriptionJourney/DisplayInscription";
import ResonanceLoader from "../../components/atoms/ResonanceLoader/ResonanceLoader";
import StratificationPage from "../../components/organisms/Home/InscriptionJourney/StratificationPage";
import DisplaySites from "../../components/organisms/Home/InscriptionJourney/DisplaySites";
import DisplayNotIncluded from "../../components/organisms/Home/InscriptionJourney/DisplayNotIncluded";
import { useTranslation } from "react-i18next";
import { postConsent, postNewUser } from "../../services/user.service";
import { useAuth } from "../../contexts/AuthContext";
import DisplayRegistredSuccessfully from "../../components/organisms/Home/InscriptionJourney/DisplayRegistredSuccessfully";
import { useConfig } from "../../contexts/ConfigContext";
import { getWelcomeInfo } from "../../services/weclome.service";
import useQuery from "../../hooks/useQuery";
import Info from "../../components/atoms/ErrorComponent";
import useToast from "../../hooks/useToast";
import { useFetch } from "../../hooks/useFetch";
import { getStudySlug } from "../../helper/study";
import DisplayInvitation from "../../components/organisms/Home/InscriptionJourney/DisplayInvitation";
import DisplayInitIdentification from "../../components/organisms/Home/InscriptionJourney/DisplayInitIdentification";
import { RoleEnum } from "../../types/invitation.types";
import ResetPwdModal from "../Profile/ResetPasswordModal";
import { getStudyLangs } from "../../services/study.service";
import { debug } from "console";

const DEFAULT_DISPLAY: WelcompeStepEnum = WelcompeStepEnum.HOME;

interface HomeContextType {
  welcomeData: WelcomeType;
  selectedRole: ProfileType;
  currentStep: WelcompeStepEnum;
  previousStep: WelcompeStepEnum;
  setCurrentStep(step: WelcompeStepEnum): void;
  goPrevious(): void;
  createUser(role: ProfileType): void;
}

const NO_PROIVDER_MESSAGE = "HomeContext - no provider";

const HomeContextDefaultValue: HomeContextType = {
  welcomeData: undefined,
  selectedRole: undefined,
  currentStep: undefined,
  previousStep: undefined,
  setCurrentStep: () => console.log(NO_PROIVDER_MESSAGE),
  goPrevious: () => console.log(NO_PROIVDER_MESSAGE),
  createUser: () => console.log(NO_PROIVDER_MESSAGE),
};

const HomeContext = createContext<HomeContextType>(HomeContextDefaultValue);

const HomeContextProvider = HomeContext.Provider;

export const useHomeContext = () => useContext(HomeContext);

const HomeContent = (): ReactElement => {
  const query = useQuery();
  const invitation_token = query.get("invitation");
  const forget_password_token = query.get("forget_password_token");
  const login = query.get("login");
  const channel = query.get("channel");

  const [steps, setSteps] = useState<WelcompeStepEnum[]>([DEFAULT_DISPLAY]);
  const [isResetPwdModalOpen, setIsResetPwdModalOpen] = useState(!!forget_password_token);
  const [loading, setLoading]= useState<boolean>();
  const setCurrentStep = useCallback(
    (step: WelcompeStepEnum) => {
      setSteps([...steps, step]);
    },
    [steps]
  );

  const currentStep = useMemo(() => steps[steps.length - 1], [steps]);
  const previousStep = useMemo(() => steps.length > 1 && steps[steps.length - 2], [steps]);

  const goPrevious = useCallback(
    () => steps.length > 1 && setSteps(steps.slice(0, -1)),
    [steps]
  );

  const [inclusionContentId, setInclusionContentId] = useState<number | null>(
    null
  );

  const [userInfo, setUserInfo] = useState<any>();
  const [consent, setConsent] = useState<any>();
  const { setRole, selectedRole, study } = useConfig();

  const slug = useMemo(getStudySlug, []);
  const has_consent = selectedRole?.consent?.has_consent;
  const present = useToast();
  const { getCurrent, endSession, isInitialized, startSession, user } =
    useAuth();

  const { t, i18n } = useTranslation();

  const {
    isError,
    isLoading,
    data: welcomeData,
  } = useFetch<WelcomeType>(
    "welcome",
    getWelcomeInfo,
    { slug, lang: i18n.language, invitation_token },
    {
      enabled: Boolean(slug && i18n.language),
    }
  );
  
  useEffect(() => {
    const userPreferredLang = localStorage.getItem("brows_lang")
    if (userPreferredLang) {
      getStudyLangs(study.id).then(({ data }) => {
        i18n.changeLanguage(userPreferredLang);
      }).catch()
    } else {
      getStudyLangs(study.id).then(({ data }) => {
        i18n.changeLanguage(data.primary_lang);
      }).catch()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onStratification = useCallback(
    async (stratification, consent) => {
      try {
        const User = user ? user : consent.data.userAuth
        const userMock = { ...consent.data };
        delete consent.data
        const body: postConsentRequestType = {
          user: userInfo ? userInfo.user : userMock.user,
          identification_questions: userInfo ? userInfo.identification_questions : userMock.identification_questions,
          consent: consent,
          stratification_questions: stratification,
        };
        setLoading(true)
        await postConsent(welcomeData.id, User.id, body);
        await getCurrent();

        if (
          welcomeData.invitations?.to_user.user_status ===
          UserStatus.INCLUSION_EN_ATTENTE_DE_VALIDATION
        ) {
          setCurrentStep(WelcompeStepEnum.INVITATION);
          return;
        }

        if (selectedRole.attributes.is_site_requested) {
          setCurrentStep(WelcompeStepEnum.SITES);
        } else {
          setCurrentStep(WelcompeStepEnum.REGISTRED_SUCCESSFULLY);
        }
      } catch (e) {
        present(e?.response?.data?.message);
      }
      finally{
        setLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userInfo, selectedRole, user, welcomeData]
  );

  const handlePreStratification = useCallback(
    (cons) => {
      switch (selectedRole.name) {
        case RoleEnum.SUBJECT:
          selectedRole.attributes.has_stratification ? setCurrentStep(WelcompeStepEnum.STRATIFICATION) : onStratification([], cons);
          break;
        default:
          onStratification([], cons);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedRole, userInfo, welcomeData]
  );

  const handleNextInscription = useCallback((data) => {
    if (has_consent) {
      setCurrentStep(WelcompeStepEnum.CONSENT);
    } else {
      const currentConsent = {
        id: selectedRole.consent.id,
        is_validated: true,
        validation_date: new Date().toISOString(),
        version: 1,
        signature: "signature",
        data: data
      }
      setConsent(currentConsent)
      handlePreStratification(currentConsent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [has_consent, selectedRole]);

  const createUser = async (role: ProfileType): Promise<void> => {
    try {
      const userInfos: PostUserType = {
        role_id: role?.id,
        lang: "fr",
      };

      const invitation = query.get("invitation");

      if (invitation) {
        userInfos.invitation_token = invitation;
      }
      
      console.log("welcomeData.id : " + welcomeData.id)
      console.log("userInfos.role_id : " + userInfos.role_id)
      console.log("userInfos.lang : " + userInfos.lang)

      const { data } = await postNewUser(welcomeData.id, userInfos);
      await startSession(data.token);
      setCurrentStep(WelcompeStepEnum.ON_BOARDING);
    } catch (e) {
      console.error(e);
      present(e?.response?.data?.message);
    }
  };

  const onProfileSelection = (newRole: ProfileType): void => {
    setRole(newRole);
    if (
      newRole.signin_criteria.method ===
      IdentificationMethodEnum.WITHOUT_IDENTIFICATION
    ) {
      createUser(newRole);
    } else {
      setCurrentStep(WelcompeStepEnum.HAS_ACCOUNT);
    }
  };

  useEffect(() => {
    (async () => {
      if (isInitialized && welcomeData) {
        await endSession();
        if (welcomeData.roles.length > 0) {
          onProfileSelection(welcomeData.roles[0]);
        }
      }
    })();
  }, [isInitialized, welcomeData]);

  useEffect(
    function () {
      const profileId = welcomeData?.invitations?.to_user?.profile;
      if (profileId && welcomeData) {
        const role = welcomeData.roles.find((role) => role.id === profileId);
        setRole(role);
      }
      else if (selectedRole && welcomeData) {
        const selectedRoleData = welcomeData.roles.find((role) => role.id === selectedRole.id);
        setRole(selectedRoleData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [welcomeData]
  );

  const StepRender = useMemo(
    function () {
      const componentsByStep: Record<WelcompeStepEnum, () => ReactElement> = {
        HOME: () => <DisplayHasAccount />,
        HOME_DETAILS: () => <DisplayStudyDetails />,
        PROFILE_SELECTOR: () => <DisplayProfileSelector />,
        HAS_ACCOUNT: () => <DisplayHasAccount />,
        ON_BOARDING: () => <OnBoarding />,
        LOGIN: () => <DisplayLogin />,
        CONSENT: () => (
          <DisplayConsent
            setConsent={setConsent}
            onNextClick={(cons) => handlePreStratification(cons)}
          />
        ),
        INCLUSION: () => (
          <DisplayInclusion setInclusionContentId={setInclusionContentId} />
        ),
        INSCRIPTION: () => (
          <DisplayInscription
            onNextClick={(nextClickdata) => handleNextInscription(nextClickdata)}
            saveUserInfos={setUserInfo}
            userInfo={userInfo}
          />
        ),
        STRATIFICATION: () => (
          <StratificationPage
            onNextClick={(strats) => onStratification(strats, consent)}
          />
        ),
        SITES: () => <DisplaySites />,
        NOT_INCLUDED: () => (
          <DisplayNotIncluded contentId={inclusionContentId} />
        ),
        REGISTRED_SUCCESSFULLY: () => <DisplayRegistredSuccessfully />,
        INVITATION: () => <DisplayInvitation />,
        INIT_IDENTIFICATION: () => <DisplayInitIdentification />,
      };

      return componentsByStep[currentStep];
    },
    [consent, currentStep, handleNextInscription, handlePreStratification, inclusionContentId, onStratification, userInfo]
  );

  if (isLoading) return <ResonanceLoader />;
  if (loading) return <ResonanceLoader />;

  if (isError)
    return (
      <Info
        title={t("common_server_error_title")}
        description={t("common_server_error_desc")}
        actionText={t("common_go_back")}
      />
    );

  return (
    <HomeContextProvider
      value={{
        welcomeData,
        selectedRole,
        previousStep,
        currentStep,
        goPrevious,
        setCurrentStep,
        createUser,
      }}
    >
      <StepRender />
      <ResetPwdModal
        isOpen={isResetPwdModalOpen}
        onDismiss={() => {
          setIsResetPwdModalOpen(false);
          window.history.replaceState({}, document.title, window.location.pathname);
        }}
        login={login}
        channel= {channel}
        reset_password_token={forget_password_token}
      />
    </HomeContextProvider>
  );
};

export default HomeContent;

export const home = {
  home_profile_selector_text: 'Selecciona un perfil',
  home_has_account_true: 'No, ya participo',
  home_has_account_false: 'Sí, me uno al estudio!',
  home_is_new: '¿Eres nuevo?',
  home_forgot_password: '¿Olvidaste tu contraseña?',
  home_inscription_title: 'Registro',
  home_consent_title: 'Consentimiento',
  home_content_check_text: 'Al marcar esta casilla, confirmo que estoy de acuerdo con todos los términos del documento de información',
  home_content_none_text: 'Sin consentimiento',
  home_not_consent_text: 'No estoy de acuerdo con todos los términos',
  home_content_signature_text: 'Firma abajo para dar tu consentimiento',
  home_inclusion_placeholder: 'Selecciona uno',
  home_inclusion_answer_true: 'Sí',
  home_inclusion_answer_false: 'No',
  home_inclusion_on: 'Sobre ti',
  home_inclusion_off: 'Sin pregunta de inclusión',
  home_identifier_title: 'Elige tu identificador',
  home_contact_title: 'Contactarte',
  home_contact_description: 'Para que el estudio funcione, recibirás recordatorios e información hasta 2 veces al mes.',
  home_inscription_identifier: 'Correo electrónico o apodo',
  home_inscription_firstname: 'Nombre',
  home_inscription_lastname: 'Apellido',
  home_inscription_nickname: 'Apodo',
  home_inscription_email: 'Correo electrónico',
  home_inscription_phone: 'Teléfono',
  home_inscription_external_id: 'Número de expediente',
  home_inscription_off: 'Sin identificación',
  home_secret_questions: 'Preguntas secretas',
  home_secret_question_date_placeholder: 'Selecciona una fecha',
  home_secret_question_number_placeholder: 'Ingresa un número',
  home_secret_question_text_placeholder: 'Ingresa un texto',
  home_password_title: 'Crea tu contraseña',
  home_password_placeholder: 'Contraseña',
  home_password_placeholder_confirm: 'Confirmar contraseña',
  home_login_email_placeholder: 'Correo electrónico o apodo',
  home_login_nickname_placeholder: 'Apodo, correo electrónico o teléfono',
  home_login_file_placeholder: 'Ingresa número de expediente',
  home_password_description: 'La contraseña debe tener al menos 8 caracteres y contener mayúsculas, minúsculas, símbolos y números.',
  home_login_title: 'Iniciar sesión',
  home_login_subtitle: '¡Qué bueno verte de nuevo!',
  home_login_button: 'Iniciar sesión',
  home_login_password_placeholder: 'Tu contraseña',
  home_stratification_title: '',
  home_stratification_placeholder: 'Selecciona uno',
  home_sites_text: 'Afiliación del sitio',
  home_subsites_text: 'Afiliación del sub-sitio',
  home_do_you_have_account: '¿Tienes una cuenta?',
  home_researchers_website: 'Sitio web de los investigadores',
  home_registred_successfully: 'Felicidades, te has registrado con éxito.',
  home_inscription_error_EMAIL_EXIST: 'Ya existe una cuenta con este correo electrónico',
  home_inscription_error_NICKNAME_EXIST: 'Ya existe una cuenta con este apodo',

  // Adición de Synakene
  home_welcome: "¡Bienvenido a Mentalo!",
  home_description: "Participa en este gran estudio y avanza en la investigación sobre el bienestar mental de los jóvenes de 11 a 24 años.",
  home_anonymous_data: "Tus datos son confidenciales; los investigadores no tendrán acceso a tu identidad.",
  home_new: "¿Eres nuevo?",
  home_copy_link: "Copiar el enlace del estudio",
  home_copied: "¡El enlace ha sido copiado con éxito!",

  home_add_mentalo: "Yo agrego Mentalo",
  home_story_telling_title: "Narración",
  home_story_telling_text: "Narración",

  home_not_included: "Gracias por tu interés, pero debes tener entre 11 y 24 años y vivir en Francia.",
  home_not_included_desc: "Para ayudarnos, puedes compartir el estudio con las personas a tu alrededor."
};

export const home = {
  home_profile_selector_text: 'Sélectionner un profil',
  home_has_account_true: 'Non, je participe déjà',
  home_has_account_false: "Oui, je rejoins l'étude !",
  home_is_new: "Es-tu nouveau ?",
  home_forgot_password: 'Mot de passe oublié ?',
  home_inscription_title: 'Inscription',
  home_consent_title: 'Consentement',
  home_content_check_text: 'En cochant cette case, je confirme être en accord avec toutes les modalités du document d\'information',
  home_content_none_text: 'Pas de consentement',
  //Ajout Syanekene
  home_not_consent_text: 'Je ne suis pas en accord avec toutes les modalités',
  home_content_signature_text: 'Signez-ci dessous pour donner votre consentement',
  home_inclusion_placeholder: 'Sélectionner',
  home_inclusion_answer_true: 'Oui',
  home_inclusion_answer_false: 'Non',
  home_inclusion_on: 'À propos de toi',
  home_inclusion_off: 'Pas de question d\'inclusion',
  home_identifier_title: 'Choisis ton identifiant',
  home_contact_title: 'Te contacter',
  home_contact_description: 'Pour que l\'étude marche, tu recevras des rappels et info max 2 fois par mois.',
  home_inscription_identifier: 'E-mail ou pseudonyme',
  home_inscription_firstname: 'Prénom',
  home_inscription_lastname: 'Nom',
  home_inscription_nickname: 'Pseudonyme',
  home_inscription_email: 'Email',
  home_inscription_phone: 'Téléphone',
  home_inscription_external_id: 'Numéro de dossier',
  home_inscription_off: 'Aucune identification',
  home_secret_questions: 'Questions secrètes',
  home_secret_question_date_placeholder: 'Choisir une date',
  home_secret_question_number_placeholder: 'Saisir nombre',
  home_secret_question_text_placeholder: 'Saisir texte',
  home_password_title: 'Crée ton mot de passe',
  home_password_placeholder: 'Mot de passe',
  home_password_placeholder_confirm: 'Confirmer le mot de passe',
  home_login_email_placeholder: 'E-mail ou pseudonyme',
  home_login_nickname_placeholder: 'Pseudonyme, email, ou téléphone',
  home_login_file_placeholder: 'Saisir numéro de dossier',
  home_password_description: 'Le mot de passe doit faire au moins 8 caractères, et contenir des majuscules, des minuscules, des symboles et des chiffres.',
  home_login_title: 'Connexion',
  home_login_subtitle: 'Ravi de te revoir !',
  home_login_button: 'Se connecter',
  home_login_password_placeholder: 'Ton mot de passe',
  home_stratification_title: '',
  home_stratification_placeholder: 'Sélectionner',
  home_sites_text: 'Affection au site',
  home_subsites_text: 'Affection au sous-site',
  home_do_you_have_account: 'Vous avez un compte ?',
  home_researchers_website: 'Site des chercheurs',
  home_registred_successfully: 'Félicitations, tu es bien inscrit.',
  home_inscription_error_EMAIL_EXIST: 'Il existe déjà un compte avec cet email',
  home_inscription_error_NICKNAME_EXIST: 'Il existe déjà un compte avec ce pseudo',

  // Ajout Synakene
  home_welcome: "Bienvenue dans Mentalo !",
  home_description: "Prends part à cette grande étude et fais avancer la recherche sur le bien-être mental des 11-24 ans.",
  home_anonymous_data:"Tes données sont confidentielles, les chercheurs n'auront pas accès à ton identité.",
  home_new:"Es-tu nouveau ?",
  home_copy_link: "Copier le lien de l'étude",
  home_copied : "Le lien à bien été copié !",

  home_add_mentalo: "J'ajoute Mentalo",
  home_story_telling_title:"Storytelling",
  home_story_telling_text:"Storytelling",

  home_not_included: 'Nous te remercions pour ton intérèt mais il faut avoir entre 11 et 24 ans et habiter en France.',
  home_not_included_desc: 'Pour nous aider, tu peux partager l\'étude à ton entourage.'



};
